import request from "../utils/request";
import { iframeResize } from "iframe-resizer";

const createElementFromHTML = (htmlString) => {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  // return div.firstChild; 
  return div;
}

const noop = () => {};

const openIframeCreationForm = (url, options = {}) => {
  const callbackKO = options.callbackKO || noop;
  const callbackOK = options.callbackOK || noop;

  const html = `
    <div class="tw-py-10 tw-fixed tw-inset-0 tw-w-full tw-h-full tw-z-1050 tw-bg-black tw-bg-opacity-50 tw-duration-300 tw-overflow-y-auto" style="z-index: 1050;">
      <div class="tw-flex tw-flex-col tw-w-11/12 sm:tw-w-5/6 lg:tw-w-10/12 tw-mx-auto tw-rounded-lg tw-max-h-full tw-border tw-border-gray-300 tw-shadow-xl tw-bg-white">
        <div class="tw-flex tw-flex-row tw-justify-between tw-p-6 tw-bg-white tw-border-b tw-border-gray-200 tw-shadow-md">
            <p class="tw-font-semibold tw-text-gray-800">
              ${options.title}
            </p>
            <span data-behaviour="closeIframeCreationForm" class="tw-cursor-pointer">
              <svg class="tw-w-6 tw-h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </span>
        </div>

        <div class="tw-h-full tw-relative">
          <div id="iframeCreationFormLoading" class="tw-absolute tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full tw-bg-white">
            <div class="tw-flex tw-justify-center tw-items-center tw-space-x-1 tw-text-3xl tw-text-black">
              <svg fill='none' class="tw-w-10 tw-h-10 tw-animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                <path clip-rule='evenodd'
                  d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                  fill='currentColor' fill-rule='evenodd' />
              </svg>
              <div>Carregant ...</div>
            </div>
          </div>

          <iframe src="${url}" id="iframeCreationForm" name="iframeCreationForm" class="tw-w-full tw-h-full tw-border-0"></iframe>
        </div>
      </div>
    </div>
  `

  const node = createElementFromHTML(html);

  node.querySelector("[data-behaviour=closeIframeCreationForm]").onclick = () => {
    callbackKO();
    node.remove();
  };

  const loading = node.querySelector("#iframeCreationFormLoading")

  const iframe = node.querySelector("#iframeCreationForm");

  iframe.onload = () => {
    const form = iframe.contentDocument.querySelector("[data-iframe-target=itemCreationForm]");

    if (!form) { alert("Contingut iframe incorrecte, no es troba el formulari amb l'attribut [data-iframe-target=itemCreationForm]") }

    loading.classList.add("tw-hidden");

    iframe.contentDocument.querySelector("[data-iframe-target=closeIframeCreationForm]").onclick = () => {
      callbackKO();
      node.remove();
    };

    request.init();
    request.setCSRFToken(iframe.contentDocument.querySelector('meta[name="csrf-token"]').getAttribute("content"));

    form.onsubmit = async (ev) => {
      ev.preventDefault();

      loading.classList.remove("tw-hidden");

      const cancelTokenSource = request.getCancelTokenSource();

      const data = new FormData(form);
      data.append('format', 'json'); // force a response with json format

      try {
        const response = await request.send({
          method: form.method,
          url: form.getAttribute('action') || iframe.contentWindow.location.href,
          data: data,
          cancelToken: cancelTokenSource.token,
        });

        callbackOK(response.data);
        node.remove();
      } catch (error) {
        if (error.response && error.response.status == 422) {
          // hack: resubmit form wihtout ajax to redraw iframe content
          // so we don't need to parse the json reponse and print errors
          form.submit();
        } else {
          alert("No hem pogut guardar els canvis")
        }
      }

      return false;
    };
  };


  document.body.appendChild(node);
  iframeResize({ maxHeight: 'calc(100vh - 5rem - 60px)', scrolling: true }, '#iframeCreationForm')
};

export default openIframeCreationForm;
