// initialValues with format key:value (ex. x-data="whenUnless('type:select', 'origin:consulta')")
export default (...initialValues) => {
  const storeKey = `whenUnless-${(new Date().getTime())}`;
  
  return {
    set values(values) {
      this.$store[storeKey] = values; 
    },
    
    get values() {
      return this.$store[storeKey];
    },
    
    set(key, value) {
      this.values = {
        ...this.values,
        [key]: value
      };
    },
    
    init() {
      this.values = Object.fromEntries(
        initialValues.map((initialValue) => initialValue.split(":"))
      );
    },
    
    // ex. when('type:select,multiselect', 'origin:consulta')
    // will be true if type is select or multiselect and origin is consulta
    when(...conditions) {
      return conditions.every((condition) => {
        const [key, values] = condition.split(":");
        
        return values.split(",").includes(String(this.values[key]));
      });
    },
    
    // ex. whenOr('type:select,multiselect', 'origin:consulta')
    // will be true if type is select or multiselect or origin is consulta
    whenOr(...conditions) {
      return conditions.some((condition) => {
        const [key, values] = condition.split(":");
        
        return values.split(",").includes(String(this.values[key]));
      });
    },
    
    unless(...conditions) {
      return !this.when(...conditions);
    },
    
    unlessOr(...conditions) {
      return !this.whenOr(...conditions);
    },
  }
}
