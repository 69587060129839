import Alpine from 'alpinejs';
import whenUnless from '../alpine/when-unless'

import "unpoly/unpoly"
import "unpoly/unpoly-bootstrap3"
import "unpoly/unpoly.css"
import "unpoly/unpoly-bootstrap3.css"

import "../tailwind.css"

import openIframeCreationForm from "../forms/iframe_creation_form";

window.Alpine = Alpine
Alpine.data('whenUnless', whenUnless);
Alpine.start()

window.up.network.config.cacheSize = 0;

window.Mobert = window.Mobert || {};
window.Mobert.openIframeCreationForm = openIframeCreationForm;